import { BaseDeck, BaseDeckFirestore } from "@types";
import { firestore } from "@utils/firebase/firebase-init";
import { query, collection, orderBy, onSnapshot } from "firebase/firestore";

// Function to fetch base decks for a chapter
export const getBaseDecks = (
  {
    topicId,
    chapterId,
  }: {
    topicId: string;
    chapterId: string;
  },
  callback: (decks: BaseDeck[]) => void,
  onError: (error: Error) => void
) => {
  return onSnapshot(
    query(
      collection(firestore, "topics", topicId, "chapters", chapterId, "decks"),
      orderBy("sortOrder")
    ),
    (snapshot) => {
      const decks = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as BaseDeckFirestore),
      }));
      callback(decks);
    },
    onError
  );
};
