import { Chapter, ChapterFirestore, TopicWithChapters } from "@types";
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  doc,
} from "firebase/firestore";
import { firestore } from "./firebase-init";

export const subscribeToChapters = (
  topic: TopicWithChapters,
  callback: (chapters: Chapter[]) => void,
  onError: (error: Error) => void
) => {
  const topicRef = doc(firestore, "topics", topic.id);

  const unsubscribers = onSnapshot(
    query(
      collection(firestore, topicRef.path, "chapters"),
      orderBy("sortOrder")
    ),
    (snapshot) => {
      const chapters = snapshot.docs.map((doc) => {
        const chapter = {
          id: doc.id,
          ...(doc.data() as ChapterFirestore),
          decks: [],
          // Add the topic title, id and key to the chapter for easy access
          topicTitle: topic.title,
          topicKey: topic.topicKey,
          topicId: topic.id,
        };
        return chapter;
      });
      callback(chapters);
    },
    (error) => {
      onError(error);
    }
  );
  return unsubscribers;
};
