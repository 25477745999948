import React, { FC } from "react";
import clsx from "clsx";
import { CheckCircleIcon, LockClosedIcon } from "@heroicons/react/24/solid";

export interface StepIconProps {
  grade?: number;
  // The highlighted steps are are active or previous steps to the active step
  isHighlighted?: boolean;
  // The active step is the one that is currently being worked on
  isActive?: boolean;
  isDone?: boolean;
  // FIX: Whats the acutal type?
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconSize?: "small" | "medium" | "large";
  isLocked?: boolean;
  weaken?: boolean;
  hasBorder?: boolean;
  IndicatorIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const StepIcon: FC<StepIconProps> = ({
  grade,
  isHighlighted,
  isLocked,
  isActive,
  isDone,
  Icon,
  iconSize = "medium",
  weaken,
  hasBorder,
  IndicatorIcon,
}) => {
  return (
    <span
      className={clsx(
        isLocked
          ? "bg-slate-100 group-hover:bg-slate-200 dark:bg-slate-700 dark:group-hover:bg-slate-600"
          : [
              hasBorder && [
                isActive
                  ? ""
                  : "border border-orange-500 group-focus:border-transparent ",
              ],
              isDone
                ? "brand-gradient"
                : isHighlighted && "bg-orange-100  dark:bg-orange-300",
              isActive &&
                "bg-orange-200 outline outline-2 outline-offset-2 outline-orange-500 dark:bg-orange-300 dark:outline-orange-500",
              !isHighlighted &&
                !isActive &&
                !weaken &&
                "bg-slate-100 dark:bg-slate-700",
              weaken &&
                "border border-slate-200 hover:border-none dark:border-slate-600",
              "group-hover:bg-orange-100 group-focus:outline group-focus:outline-2 group-focus:outline-offset-2 group-focus:outline-orange-500  group-active:bg-orange-200 dark:group-hover:bg-orange-300 group-focus:dark:outline-orange-500",
            ],
        iconSize === "small" && "h-8 w-8 lg:h-10 lg:w-10",
        iconSize === "medium" && "h-10 w-10 lg:h-12 lg:w-12",
        iconSize === "large" && "h-12 w-12 lg:h-14 lg:w-14",
        "relative flex shrink-0 items-center justify-center overflow-visible rounded-full transition"
      )}
    >
      {(isLocked || IndicatorIcon) && (
        <div
          className={clsx(
            iconSize === "small" && "-translate-y-3 translate-x-3.5 p-1.5",
            iconSize === "medium" && "-translate-y-3 translate-x-3.5 p-2",
            iconSize === "large" && "-translate-x-5 -translate-y-5 p-2.5",
            isLocked
              ? "border-slate-300 bg-slate-200 dark:border-slate-500 dark:bg-slate-500"
              : "border-orange-500 bg-orange-100 dark:border-orange-500 dark:bg-orange-400",
            "absolute rounded-full border bg-opacity-70 p-1.5 transition group-hover:scale-125  dark:bg-opacity-70"
          )}
        >
          {(IndicatorIcon && (
            <IndicatorIcon
              className={clsx(
                iconSize === "small" && "h-2 w-2 lg:h-3 lg:w-3",
                iconSize === "medium" && "h-3 w-3 lg:h-4 lg:w-4",
                iconSize === "large" && "h-4 w-4 lg:h-5 lg:w-5",
                " text-orange-600 dark:text-orange-100"
              )}
            />
          )) || (
            <LockClosedIcon
              className={clsx(
                iconSize === "small" && "h-2 w-2 lg:h-3 lg:w-3",
                iconSize === "medium" && "h-3 w-3 lg:h-4 lg:w-4",
                iconSize === "large" && "h-4 w-4 lg:h-5 lg:w-5",
                "-mt-0.5 text-slate-600 dark:text-slate-100"
              )}
            />
          )}
        </div>
      )}
      {grade ? (
        <div
          className={clsx(
            "relative",
            iconSize === "small" && "text-sm md:text-base 2xl:text-lg",
            iconSize === "medium" && "text-lg md:text-xl 2xl:text-2xl",
            iconSize === "large" && "text-xl md:text-2xl 2xl:text-3xl",
            "font-semibold text-orange-50 "
          )}
        >
          {grade}
        </div>
      ) : isDone ? (
        <CheckCircleIcon
          className={clsx(
            iconSize === "small" && "h-4 w-4 lg:h-5 lg:w-5",
            iconSize === "medium" && "h-5 w-5 lg:h-6 lg:w-6",
            iconSize === "large" && "h-6 w-6 lg:h-7 lg:w-7",
            "text-orange-50 dark:text-orange-100"
          )}
        />
      ) : (
        Icon && (
          <Icon
            className={clsx(
              isLocked
                ? "text-slate-500 group-hover:text-slate-600 dark:text-slate-300 dark:group-hover:text-slate-200"
                : [
                    isHighlighted
                      ? "text-primary-default dark:text-primary-darker"
                      : "text-slate-500  dark:text-slate-300",
                    "transition group-hover:text-orange-600 dark:group-hover:text-primary-darker",
                  ],
              iconSize === "small" && "h-4 w-4 lg:h-5 lg:w-5",
              iconSize === "medium" && "h-5 w-5 lg:h-6 lg:w-6",
              iconSize === "large" && "h-6 w-6 lg:h-7 lg:w-7"
            )}
          />
        )
      )}
    </span>
  );
};

export default StepIcon;
