import { useEffect, useState } from "react";

import {
  BaseDeck,
  ChapterPathInfo,
  DeckWithoutCards,
  ErrorMessage,
  Loading,
  UserDeckWithId,
} from "@types";
import { getBaseDecks } from "./getBaseDecks";
import { getUserDecks } from "./getUserDecks";

export const useDecksForChapter = (
  pathInfo: ChapterPathInfo
): [DeckWithoutCards[] | null, Loading, ErrorMessage] => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [baseDecks, setBaseDecks] = useState<BaseDeck[]>(null);
  const [userDecks, setUserDecks] = useState<UserDeckWithId[]>(null);
  const [decksWithProgress, setDecksWithProgress] = useState<
    DeckWithoutCards[]
  >([]);

  const { userId, topicId, chapterId } = pathInfo;

  const handleError = (error: Error) => {
    // Note: Simple error handling function; might override previous error
    setError(error);
    setIsLoading(false);
    return;
  };

  // Get base decks for the chapter
  useEffect(() => {
    if (!chapterId || !topicId || !userId) {
      setError(new Error("Missing necessary IDs"));
      return;
    }
    // Set loading to true and clear any errors before the first step of retrieving data starts
    setIsLoading(true);
    setError(null);

    const unsubscribe = getBaseDecks(
      { topicId, chapterId },
      setBaseDecks,
      handleError
    );

    return () => unsubscribe();
  }, [topicId, chapterId, userId]);

  // Get user progress for the decks
  useEffect(() => {
    if (!chapterId || !topicId) {
      setError(new Error("Missing necessary IDs"));
      return;
    }

    const unsubscribe = getUserDecks(
      { userId, topicId, chapterId },
      setUserDecks,
      handleError
    );

    return () => unsubscribe();
  }, [userId, topicId, chapterId]);

  useEffect(() => {
    if (baseDecks && userDecks) {
      const decksWithProgress = baseDecks.map((deck) => {
        const userDeck = userDecks.find((userDeck) => userDeck.id === deck.id);
        return { ...deck, ...userDeck };
      });
      setDecksWithProgress(decksWithProgress);
      setIsLoading(false);
    }
  }, [baseDecks, userDecks]);

  return [decksWithProgress, isLoading, error];
};
