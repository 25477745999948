import { UserDeck, UserDeckWithId } from "@types";
import { firestore } from "@utils/firebase/firebase-init";
import { collection, onSnapshot } from "firebase/firestore";

// Function to fetch user deck progress
export const getUserDecks = (
  {
    userId,
    topicId,
    chapterId,
  }: {
    userId: string;
    topicId: string;
    chapterId: string;
  },
  callback: (decks: UserDeckWithId[]) => void,
  onError: (error: Error) => void
) => {
  return onSnapshot(
    collection(
      firestore,
      "userProgress",
      userId,
      "forTopics",
      topicId,
      "forChapters",
      chapterId,
      "forDecks"
    ),
    (snapshot) => {
      const decks = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as UserDeck),
      }));
      callback(decks);
    },
    onError
  );
};
