import { FC } from "react";
import clsx from "clsx";
import { LockClosedIcon } from "@heroicons/react/24/solid";

import { Annotation } from "@jurahilfe/shared/components";

import { StepConfigItem } from "../stepConfig";

import ProgressBar from "./ProgressBar";
import StepIcon from "./StepIcon";
import { ArrowPathIcon, ClockIcon } from "@heroicons/react/20/solid";

export interface StepProps {
  stepNumber: number;
  step: StepConfigItem;
  onClick: (step: number) => void;
  isLast: boolean;
  isLocked: boolean;
  isActive: boolean;
  isHighlighted: boolean;
  progress?: number;
  grade?: number;
  size?: "normal" | "slim";
  weaken?: boolean;
  showIconBorder?: boolean;
  isDone?: boolean;
  resetOption?: "allowed" | "waiting" | "unspecified";
  repeatBlocked?: string;
}

const Step: FC<StepProps> = ({
  stepNumber,
  step,
  progress = 0,
  onClick,
  isLast,
  isLocked,
  isActive,
  isHighlighted,
  grade,
  size = "normal",
  weaken,
  showIconBorder,
  isDone,
  repeatBlocked,
  resetOption = "unspecified",
}) => {
  return (
    <li
      key={step.title}
      className={clsx(
        // Add "group" class for active and hover effects on the whole step
        "group relative cursor-pointer focus:outline-none",
        !isLast && "w-full"
      )}
      onClick={() => onClick(stepNumber)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick(stepNumber);
        }
      }}
      tabIndex={0}
    >
      <Annotation
        hideDelay={0}
        showDelay={0}
        className="flex w-full items-center justify-center"
        content={
          <>
            <div className="flex items-center gap-1">
              {isLocked ? (
                <LockClosedIcon className="h-3 w-3 text-slate-600 dark:text-slate-200" />
              ) : resetOption === "waiting" ? (
                <ClockIcon className="h-3 w-3 text-slate-600 dark:text-slate-200" />
              ) : resetOption === "allowed" ? (
                <ArrowPathIcon className="h-3 w-3 text-slate-600 dark:text-slate-200" />
              ) : undefined}
              <span className="text-base">{stepNumber}</span>
              <span className="w font prose min-w-max whitespace-nowrap font-semibold text-orange-500">
                {step.title}
              </span>
              {!isLast && <span>({(progress * 100).toFixed(0)}%)</span>}
            </div>
            {resetOption === "waiting" && (
              <div className="text-center text-slate-200">
                {repeatBlocked
                  ? `Wiederholung ab ${repeatBlocked} möglich.`
                  : "Wiederholung des Tests gesperrt."}
              </div>
            )}
            {/* {isLocked && (
              <div className="whitespace-nowrap text-center text-slate-200">
                Die Stufe ist noch gesperrt
              </div>
            )} */}
          </>
        }
      >
        <StepIcon
          isLocked={isLocked}
          IndicatorIcon={
            resetOption === "waiting"
              ? ClockIcon
              : resetOption === "allowed"
              ? ArrowPathIcon
              : undefined
          }
          hasBorder={showIconBorder}
          isActive={isActive}
          isHighlighted={isHighlighted}
          isDone={isDone}
          grade={grade}
          Icon={step.icon}
          iconSize={size === "slim" ? "small" : "medium"}
          weaken={weaken}
        />
        {!isLast && (
          <div className="mx-2 w-full">
            <ProgressBar
              progress={progress || 0.0}
              isHighlighted={isHighlighted}
              size={size === "slim" ? "thick" : "thin"}
              isLocked={isLocked}
            />
          </div>
        )}
      </Annotation>
    </li>
  );
};

export default Step;
