import React, { FC, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import clsx from "clsx";

import { Logo } from "@jurahilfe/shared/components";
import { ContentLimit } from "@jurahilfe/shared/types";

import { stepConfig } from "../stepConfig";

import { ProgressSteps } from "@types";
import { ToastBody, ToastHeader } from "@components/shared/Toaster";
import Step from "./Step";
import { useLockedSteps } from "@hooks/useLockedSteps";
import useStore from "@hooks/useStore";
import { getIsProBlocked } from "@utils/getIsProBlocked";

export interface StepperProps {
  progress: ProgressSteps;
  activeStep: number;
  grade?: number;
  lastTestDate?: number;
  handleStepClick: (step: number) => void;
  size?: "normal" | "slim";
  weakenLastStep?: boolean;
  highlightNextStep?: boolean;
  proContent?: boolean;
  topicKey?: ContentLimit;
}

const Stepper: FC<StepperProps> = ({
  progress,
  activeStep,
  grade,
  lastTestDate,
  handleStepClick,
  size = "normal",
  weakenLastStep,
  highlightNextStep = false,
  proContent,
  topicKey,
}) => {
  const { user } = useStore((state) => ({
    user: state.user,
  }));
  const [step2Locked, step3Locked] = useLockedSteps(progress);
  const [repeatBlocked, setRepeatBlocked] = useState<string>();

  const proBlocked = getIsProBlocked(proContent, user, topicKey);

  const handleClick = (step: number) => {
    if (proBlocked) {
      // Currently not in use, since pointer events are set to none when blocked
      toast(
        <>
          <ToastHeader>
            {/* <LockClosedIcon className="mr-0.5 -mt-0.5 inline-block h-3 w-3" />{" "} */}
            Jurahilfe.de Pro Inhalt
          </ToastHeader>
          <ToastBody>
            Upgrade erforderlich, um die Stufe{" "}
            <span className="font-semibold">{stepConfig[step].title}</span> für
            diese Inhalte freizuschalten!
          </ToastBody>
        </>,
        {
          duration: 3000,
          icon: <Logo size="xsmall" />,
        }
      );
      return;
    }

    // Optional: Handle locked test here already (e.g. modal)
    if ((step === 2 && step2Locked) || (step === 3 && step3Locked && !grade)) {
      toast(
        <>
          <ToastHeader>
            {/* <LockClosedIcon className="mr-0.5 -mt-0.5 inline-block h-3 w-3" />{" "} */}
            {stepConfig[step].title} gesperrt
          </ToastHeader>
          <ToastBody>Schließe zuerst die vorherigen Stufen ab!</ToastBody>
        </>,
        { duration: 1000 }
      );
      return;
    }

    if (handleStepClick) {
      handleStepClick(step);
    }
  };

  useEffect(() => {
    const lastTest = new Date(lastTestDate).getTime();
    const current = new Date().getTime();

    // Check if the last test was less than 3 days ago
    if (current - lastTest < 3 * 24 * 60 * 60 * 1000) {
      setRepeatBlocked(
        new Date(lastTest + 3 * 24 * 60 * 60 * 1000).toLocaleDateString("de")
      );
    }
  }, [lastTestDate]);

  return (
    <ol
      className={clsx(
        "flex w-full items-center justify-center px-3 py-2 md:px-3 md:py-3",
        proBlocked && "pointer-events-none opacity-50"
      )}
    >
      {Object.entries(stepConfig).map(([key, step], i) => {
        const isHighlighted =
          parseInt(key) <= activeStep || progress?.[key] > 0;
        const isActive = parseInt(key) === activeStep;
        const isLast = i === Object.entries(stepConfig).length - 1;
        const isLocked =
          (i === 2 && !grade && step3Locked) || (i === 1 && step2Locked);

        return (
          <Step
            key={step.title}
            stepNumber={parseInt(key)}
            step={step}
            progress={progress?.[key]}
            grade={grade && parseInt(key) === 3 ? grade : undefined}
            resetOption={
              !isLocked && parseInt(key) === 3 && progress?.[key] === 1
                ? repeatBlocked
                  ? "waiting"
                  : "allowed"
                : "unspecified"
            }
            repeatBlocked={repeatBlocked}
            onClick={handleClick}
            isDone={progress?.[key] === 1}
            isHighlighted={isHighlighted}
            isActive={isActive}
            isLast={isLast}
            // Hide locked icon if step is premium locked (cleaner UI)
            isLocked={!proBlocked && isLocked}
            weaken={weakenLastStep && isLast}
            size={size}
            // Set hasBorder to true for all steps following a step that is not locked, ecxept for the last step and steps that have been completed
            showIconBorder={
              highlightNextStep &&
              !isLast &&
              progress?.[key] !== 1 &&
              !((i === 1 && step2Locked) || (i === 2 && !grade && step3Locked))
            }
          />
        );
      })}
    </ol>
  );
};

export default Stepper;
