import clsx from "clsx";
import { FC } from "react";

export interface InfoBoxProps {
  children: React.ReactNode;
  title: string;
  className?: string;
  type?: "info" | "warning" | "error" | "success";
  highlight?: boolean;
  size?: "normal" | "slim";
}

const InfoBox: FC<InfoBoxProps> = ({
  type = "info",
  children,
  title,
  highlight,
  className,
  size = "normal",
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col md:flex-row",
        "w-full rounded-md border",
        highlight
          ? "border-orange-500"
          : [type === "info" && "border-slate-200 dark:border-slate-700"],
        className
      )}
    >
      <div
        className={clsx(
          highlight
            ? "bg-orange-500"
            : [type === "info" && "bg-slate-200 dark:bg-slate-700"],
          "flex items-center justify-center rounded-t-md px-2 md:rounded-t-none md:rounded-bl-md md:rounded-tl-md md:px-2"
        )}
      >
        <div
          className={clsx(
            highlight
              ? "text-white"
              : [type === "info" && "text-slate-800 dark:text-slate-100"],
            size === "slim" ? "py-1.5 md:py-4" : "py-1.5 md:py-8",
            "text-center font-semibold horizontal-lr md:vertical-lr"
          )}
        >
          {title}
        </div>
      </div>
      <div
        className={clsx(
          size === "slim" ? "py-2" : "py-4 md:py-5",
          "flex w-full flex-col items-center justify-center px-2 text-left text-sm text-slate-800 dark:text-slate-200 md:items-start md:px-5"
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default InfoBox;
