import React from "react";
import clsx from "clsx";

/**
 * Skeleton component for loading states
 * @param type - Type of skeleton to render, use "custom" if you want to provide your own width and height
 * @param className - Additional classes to apply
 * @param width - Width of the skeleton, provides a default if type is "custom"
 * @param height - Height of the skeleton, provides a default if type is "custom"
 * @returns Skeleton component
 */
const Skeleton = ({
  type = "custom",
  className,
  width,
  height,
}: {
  type?:
    | "small"
    | "medium"
    | "large"
    | "topicTitle"
    | "chapterTitle"
    | "deckTitle"
    | "custom";
  className?: string;
  width?: string;
  height?: string;
}) => {
  // Define classes for different types
  const sizeClasses = {
    small: "h-4 w-24",
    medium: "h-8 w-48",
    large: "h-12 w-96",
    topicTitle: "h-8 w-72",
    chapterTitle: "h-8 w-full max-w-[80%] mb-2",
    deckTitle: "h-7 my-3 w-full",
    custom: "",
  };

  // Use custom width and height if provided, otherwise use predefined sizes
  const skeletonClasses =
    type === "custom" ? `${width || ""} ${height || ""}` : sizeClasses[type];

  // Combine the classes for base styling and the size-specific classes
  return (
    <div
      className={clsx(
        "animate-pulse rounded bg-gray-300/50 dark:bg-gray-700/20",
        skeletonClasses,
        className
      )}
    />
  );
};

export default Skeleton;
