import { useState, useEffect } from "react";

import { TopicTitle } from "@jurahilfe/shared/types";

import { ErrorMessage, Loading, Topic, TopicWithChapters } from "@types";

import { getTopicByTitle } from "@utils/firebase/getTopicByTitle";
import { subscribeToChapters } from "@utils/firebase/subscribeToChapters";

// TODO: Add option to fetch user progress when providing userId
export const useOutlineForTopic = (
  topicTitle: TopicTitle | false,
  includeDecks?: boolean
): [TopicWithChapters | null, Loading, ErrorMessage] => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [topicData, setTopicData] = useState<TopicWithChapters>();

  useEffect(() => {
    if (!topicTitle) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    const unsubscribers = [];

    getTopicByTitle(topicTitle)
      .then((topic: Topic) => {
        if (!topic) {
          setIsLoading(false);
          return;
        }

        const topicWithChapters = {
          ...topic,
          chapters: [],
        } as TopicWithChapters;

        setTopicData(topicWithChapters);

        const unsubChapters = subscribeToChapters(
          topicWithChapters,
          (chapters) => {
            topicWithChapters.chapters = chapters;
            setTopicData((prev) => ({ ...prev, chapters }));
            setIsLoading(false);
          },
          (error) => {
            console.log("Error subscribing to chapters", error);
            setError(error);
            setIsLoading(false);
            return;
          }
        );
        unsubscribers.push(unsubChapters);
      })
      .catch((error) => {
        console.log("Error getting topic data", error);
        setError(error);
        setIsLoading(false);
        return;
      });

    return () => {
      unsubscribers.forEach((unsub) => unsub());
    };
  }, [topicTitle, includeDecks]);

  return [topicData, isLoading, error];
};
