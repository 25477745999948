import {
  collection,
  getDocs,
  query,
  where,
  limit,
  doc,
  getDoc,
} from "firebase/firestore";

import { TopicTitle } from "@jurahilfe/shared/types";

import { firestore } from "./firebase-init";
import { Topic, TopicOutline, UserTopic } from "@types";

/**
 * Fetches a topic by its title from firebase
 * If a userId is provided, it will also fetch the user's progress for that topic
 */
export const getTopicByTitle = async (
  title: TopicTitle,
  userId?: string
): Promise<TopicOutline | Topic | null> => {
  let topic: TopicOutline | Topic | null = null;

  const topicsRef = collection(firestore, "topics");
  const baseSnaps = await getDocs(
    query(
      collection(firestore, topicsRef.path),
      where("title", "==", title),
      limit(1)
    )
  );

  if (baseSnaps.empty) {
    return null;
  }

  const baseSnap = baseSnaps.docs[0];

  const baseTopic: Topic = {
    id: baseSnap.id,
    ...(baseSnap.data() as Topic),
  } as Topic;

  topic = {
    ...baseTopic,
    userProgress: null,
  };

  if (userId) {
    console.log("Getting progress for topic for user", userId);
    // Fetch user topic from firebase
    const userTopicRef = doc(
      firestore,
      "userProgress",
      userId,
      "forTopics",
      baseSnap.id
    );

    const userSnap = await getDoc(userTopicRef);

    topic = {
      ...baseTopic,
      userProgress: {
        ...(userSnap.data() as UserTopic),
      },
    };
  }

  return topic;
};
