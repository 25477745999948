import clsx from "clsx";
import React, { FC } from "react";

export interface ProgressBarProps {
  progress: number;
  isHighlighted?: boolean;
  size?: "thin" | "thick" | "extraThick";
  isLocked?: boolean;
}

const ProgressBar: FC<ProgressBarProps> = ({
  progress,
  isHighlighted,
  size = "thin",
  isLocked,
}) => {
  const progressPercentage = Math.round(progress * 100);
  return (
    <div className="flex w-full px-1 md:px-2">
      <div
        className={clsx(
          isHighlighted
            ? "bg-slate-200 dark:bg-slate-600/80"
            : "bg-slate-100 dark:bg-slate-700/80",
          "group-hover:bg-slate-200 dark:group-hover:bg-slate-600",
          progressPercentage === 100 && "",
          size === "thin"
            ? "h-2 rounded-full sm:h-2.5"
            : size === "thick"
            ? "h-2.5 rounded"
            : "h-2.5 rounded-lg sm:h-3",
          "w-full overflow-hidden "
        )}
      >
        <div
          style={{ width: `${progressPercentage}%` }}
          className={clsx(
            "h-full",
            "transition-all",
            "duration-500",
            "rounded-sm",
            // progressPercentage < 70 ? "bg-orange-200" : "brand-gradient"
            isLocked ? "bg-slate-300 dark:bg-slate-400" : "brand-gradient"
          )}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
