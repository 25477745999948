import { ProgressSteps } from "@types";
import { useEffect, useState } from "react";

export const useLockedSteps = (progress: ProgressSteps) => {
  const [step2Locked, setStep2Locked] = useState(true);
  const [step3Locked, setStep3Locked] = useState(true);

  useEffect(() => {
    // If progress is not defined or both steps are not completed, lock all steps
    if (!progress || (progress[1] !== 1 && progress[2] !== 1)) {
      setStep2Locked(true);
      setStep3Locked(true);
      return;
    }

    if (progress[1] === 1 && progress[2] !== 1) {
      setStep2Locked(false);
      setStep3Locked(true);
    } else if (progress[1] !== 1 && progress[2] === 1) {
      setStep2Locked(false);
      setStep3Locked(false);
    } else if (progress[1] === 1 && progress[2] === 1) {
      setStep2Locked(false);
      setStep3Locked(false);
    }
  }, [progress]);

  return [step2Locked, step3Locked];
};
